"use client";

import { useCallback, useEffect } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";

import { FormErrorNotification, useAuth } from "@/components";
import { getErrorMessages } from "@/utils/error";
import { sendError } from "@/utils/sentry";

import { useLogin } from "./helpers";
import styles from "./Login.module.scss";
import { LoginFormSchema, LoginFormSchemaValue } from "./schema";

interface LoginFormProps {
  isLabo?: boolean;
}

export function LoginForm({ isLabo }: LoginFormProps): React.ReactNode {
  const {
    control,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty, isSubmitting },
  } = useForm<LoginFormSchemaValue>({
    resolver: zodResolver(LoginFormSchema),
    mode: "onChange",
  });

  const { login } = useLogin({ isLabo });
  const { clearAccessToken } = useAuth();

  const onSubmit = useCallback(
    async (formValues: LoginFormSchemaValue) => {
      try {
        // TODO: Nuxt移行後にrouter.pushにする
        window.location.href = await login(formValues);
      } catch (error) {
        sendError(error);
        const message = getErrorMessages(error).join("\n");
        setError("root", { type: "custom", message });
      }
    },
    [login, setError]
  );

  useEffect(() => {
    // 不具合を防ぐためログアウト状態にリセットする
    clearAccessToken();
  }, [clearAccessToken]);

  return (
    <form id="login" onSubmit={handleSubmit(onSubmit)}>
      <FormErrorNotification errors={errors} />
      <label htmlFor="CustomerEmail">メールアドレス</label>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <input
            id="CustomerEmail"
            type="email"
            autoCorrect="off"
            autoCapitalize="off"
            autoComplete="username"
            autoFocus
            className={clsx(
              "mg__top__xs",
              styles.input,
              errors.email && "mg__bottom__xs",
              errors.email && styles.inputError,
              !errors.email && "mg__bottom__m"
            )}
            {...field}
          />
        )}
      />
      {errors.email && <p className="text__s mg__bottom__m text__red">{errors.email.message}</p>}
      <label htmlFor="CustomerPassword">パスワード</label>
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <input
            id="CustomerPassword"
            type="password"
            autoComplete="current-password"
            className={clsx(
              "mg__top__xs",
              styles.input,
              errors.password && "mg__bottom__xs",
              errors.password && styles.inputError,
              !errors.password && "mg__bottom__m"
            )}
            {...field}
          />
        )}
      />
      {errors.password && (
        <p className="text__s mg__bottom__m text__red">{errors.password.message}</p>
      )}
      <div className="text__center pd__top__m">
        <button
          id="is_registered_account"
          form="login"
          className="btn yellow inline round text__black text__m"
          disabled={isSubmitting || !isDirty || !isValid}
        >
          ログイン
        </button>
      </div>
    </form>
  );
}
