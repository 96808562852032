"use client";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { keepPreviousData } from "@tanstack/react-query";
import clsx from "clsx";
import { useRouter } from "next/navigation";
import { DeepPartial } from "react-hook-form";
import { scroller } from "react-scroll";
import { FadeLoader } from "react-spinners";

import {
  AmazonPayPanel,
  Button,
  htmlToast,
  LoadingOverlay,
  OrderSummary,
  Row,
  useAmazonPay,
} from "@/components";
import { useAuth } from "@/components/domains/auth";
import { CheckoutProgressBar } from "@/components/domains/checkout/CheckoutProgressBar/CheckoutProgressBar";
import {
  CheckoutForm,
  CheckoutFormValues,
  convertFormValuesToStorage,
  getDefaultFormValues,
} from "@/components/domains/checkout-form";
import { getCustomerIsRegistered, getPoints } from "@/generated/axios-functions/payseAPI";
import { useSaveCheckoutProgress } from "@/generated/open-api/cart/cart";
import { CartModel } from "@/models/cart/type";
import { convertProductToNuxtStoreProduct } from "@/models/product/converters";
import { useGetParsedCartSuspense } from "@/queries";
import {
  setClientCartCart,
  useClientAmazonPay,
  useClientCartForm,
  useClientCustomerCustomer,
} from "@/storage";
import { withCsr, withSuspense } from "@/utils";
import {
  fireAmazonPayAction,
  getFormDataForAmazonPay,
  setFormDataForAmazonPay,
  getAmazonCheckoutUrl,
} from "@/utils/amazon";
import { theme } from "@/utils/color";
import { getErrorMessages } from "@/utils/error";
import { useAsync, useCount, useIsAppWebView, useOnce } from "@/utils/hooks";
import { objectMerge } from "@/utils/object";

import styles from "./Checkout.module.scss";
import { useCartParamsState, useConfirm, useFormPercentage } from "./helpers";

const formContainerId = "one_page_checkout_form";

export const Checkout = withCsr(
  withSuspense(
    function Checkout(): React.ReactNode {
      const { cartParams, setCouponCode, setPoints } = useCartParamsState();
      const { data: cart } = useGetParsedCartSuspense(cartParams, {
        placeholderData: keepPreviousData,
      });
      if (!cart) {
        // TODO: ここに来た時点で不正、エラーハンドリングが必要
        throw new Error("カートが取得できませんでした");
      }

      useEffect(() => {
        // Nuxtで利用するためにCartをセット
        setClientCartCart(cart);
      }, [cart]);

      const { customer } = useClientCustomerCustomer();
      const { isLoggedIn } = useAuth();
      const [loadingCount, startLoading, endLoading] = useCount();
      const isLoading = loadingCount > 0;

      // このフォームはローカルストレージに保存しており、確認画面で遷移しても使えるようにしている
      const { form, setForm } = useClientCartForm();
      useOnce(() => {
        // フォームの初期化
        if (cart) {
          setForm((prev) =>
            objectMerge(prev, {
              products: cart.products?.map(convertProductToNuxtStoreProduct) ?? [],
              set_names: cart.metadata.setNames,
              accepts_marketing: prev?.accepts_marketing ?? true,
              isLP: false,
              isInvite: false,
            })
          );
        }
      });

      const scrollToForm = useCallback(() => {
        scroller.scrollTo(formContainerId, {
          duration: 500,
          smooth: true,
        });
      }, []);

      // AmazonPayの制御
      const amazonPayData = useAmazonPay();
      const {
        amazonPayCheckoutSessionId,
        amazonPayCheckoutData,
        amazonPayCheckoutError,
        amazonPayEnable,
      } = amazonPayData;

      const { setAmazonPayData } = useClientAmazonPay();
      useOnce(() => {
        setAmazonPayData((prev) => ({
          ...prev,
          // confirm画面で使うため保存しておく
          amazonPayEnable: amazonPayEnable,
          amazonPayCheckoutSessionId: amazonPayCheckoutSessionId,
        }));
      });
      // AmazonPayのチェックアウトデータの取得でエラーが発生した場合の処理
      useOnce(async () => {
        if (amazonPayCheckoutError) {
          alert(amazonPayCheckoutError);
          await fireAmazonPayAction(amazonPayCheckoutSessionId, "changeAddress");
          return;
        }
      });

      const updateCheckoutUrl = useCallback(
        async (cart: CartModel) => {
          if (!amazonPayCheckoutSessionId || !amazonPayEnable) return;
          const { url: checkoutUrl } = await getAmazonCheckoutUrl({
            amazonCheckoutSessionId: amazonPayCheckoutSessionId,
            amount: String(cart.totalPrice),
            is_subscription: cart.isSubscription ?? false,
            review_return_path: "/checkout/",
            result_return_path: "/checkout/confirm",
          });
          if (checkoutUrl) {
            setAmazonPayData((prev) => ({
              ...prev,
              amazonPayCheckoutUrl: checkoutUrl,
            }));
          }
        },
        [amazonPayCheckoutSessionId, amazonPayEnable, setAmazonPayData]
      );
      useOnce(() => {
        if (cart) updateCheckoutUrl(cart);
      }, amazonPayCheckoutSessionId);

      const router = useRouter();

      // AmazonPayと同一のメールアドレスのアカウントがあるかどうか
      const [hasSameEmailAccount, setHasSameEmailAccount] = useState(false);
      useOnce(async () => {
        if (amazonPayEnable) {
          const registeredRes = await getCustomerIsRegistered({
            email: amazonPayCheckoutData.email!,
          });
          setHasSameEmailAccount(registeredRes.result === "ok");
        }
      });

      const redirectCart = useCallback(
        (message?: string) => {
          message && alert(message);
          router.push("/cart");
        },
        [router]
      );

      // 初回のカートのバリデーション
      useOnce(() => {
        if (!cart || !cart.products?.length) {
          redirectCart();
          return;
        }
        const hasNormalProducts = (cart.normalProducts?.length ?? 0) > 0;
        const hasFreezedProducts = (cart.freezeProducts?.length ?? 0) > 0;
        if (
          // 通常商品、冷凍商品がある場合は、各々のバリデーション結果が有効であること
          (hasNormalProducts && !cart.validPurchase) ||
          (hasFreezedProducts && !cart.validFreezePurchase)
        ) {
          // 2600円未満でcheckout画面に到達するとここにくる
          redirectCart();
        }
      });

      // フォームの状態管理
      const [formValues, setFormValues] = useState<DeepPartial<CheckoutFormValues>>(() =>
        getDefaultFormValues({
          localStorageForm: form,
          sessionStorageForm: getFormDataForAmazonPay("amazon_pay_input_data"),
          customer,
          amazonPayData,
        })
      );

      const { mutateAsync: validateForm } = useSaveCheckoutProgress();
      const handleChangedEmail = useCallback(
        async (email: string) => {
          if (email) {
            try {
              await validateForm({
                data: { email, products: cart.products.map(convertProductToNuxtStoreProduct) },
              });
            } catch (e) {
              htmlToast.error(getErrorMessages(e));
            }
          }
        },
        [cart.products, validateForm]
      );

      const isPaymentMethodUnnecessary = useMemo(() => {
        return (
          isLoggedIn &&
          cart &&
          cart.usePointType === "all" &&
          cart.totalPrice === 0 &&
          !cart.isSubscription
        );
      }, [cart, isLoggedIn]);

      const handleClickAmazonPay = useCallback(() => {
        setFormDataForAmazonPay("amazon_pay_input_data", convertFormValuesToStorage(formValues));
      }, [formValues]);

      // 未入力項目の計算
      const { percentage: formPercentage, unfilledCount: formUnfilledCount } = useFormPercentage({
        formValues,
        isPaymentMethodUnnecessary,
        isLoggedIn,
      });

      const [isFormHidden, setIsFormHidden] = useState(() => {
        // 半分以上入力済みの場合はフォームを表示する
        if (amazonPayEnable || isLoggedIn || formPercentage >= 50) {
          return false;
        }
        return true;
      });

      // ------ ポイント利用 ------
      // 現在保持しているポイント
      const [currentPoints, setCurrentPoints] = useState(0);
      // ポイント取得のハンドリング
      useAsync(async () => {
        // ログインしてたら所持ポイントを取得しておく
        if (isLoggedIn) {
          const { total } = await getPoints();
          if (total) {
            // なぜかstringで返ってくるので数値に変換
            setCurrentPoints(Number(total));
          }
        }
      }, [isLoggedIn]);
      const confirm = useConfirm();
      const handleClickConfirm = useCallback(async () => {
        try {
          startLoading();
          await confirm({
            cart,
            formValues,
            isLoggedIn,
            isPaymentMethodUnnecessary,
            hasSameEmailAccount,
            amazonPayEnable,
            amazonPayCheckoutSessionId,
          });
          router.push("/checkout/confirm");
        } catch (e) {
          htmlToast.error(getErrorMessages(e));
          scrollToForm();
        } finally {
          endLoading();
        }
      }, [
        startLoading,
        confirm,
        cart,
        formValues,
        isLoggedIn,
        isPaymentMethodUnnecessary,
        hasSameEmailAccount,
        amazonPayEnable,
        amazonPayCheckoutSessionId,
        router,
        scrollToForm,
        endLoading,
      ]);

      const isAppWebView = useIsAppWebView();

      return (
        <LoadingOverlay isLoading={isLoading}>
          <div id={formContainerId}>
            <div className="container">
              <main className={clsx("row", styles.reverse)}>
                <OrderSummary
                  cart={cart}
                  currentPoints={currentPoints}
                  onChangeCoupon={setCouponCode}
                  onChangePoint={setPoints}
                />
                <div className={clsx("col-12", "col-m-7")}>
                  <p className={clsx("text__l", "text__bold")}>ご注文方法</p>
                  {!amazonPayEnable && (
                    <AmazonPayPanel
                      onClickPayButton={handleClickAmazonPay}
                      isSubscription={cart?.isSubscription ?? false}
                      reviewReturnPath="/checkout"
                      resultReturnPath="/checkout/confirm"
                    />
                  )}
                  <CheckoutForm
                    cart={cart}
                    isLoggedIn={isLoggedIn}
                    defaultValues={formValues}
                    onChange={setFormValues}
                    showBackButton={!isAppWebView}
                    showLogin={true}
                    showCoupon={false}
                    hiddenForm={isFormHidden}
                    confirmButtonVariants="blue"
                    onConfirm={handleClickConfirm}
                    onChangedEmail={handleChangedEmail}
                  />
                  {isFormHidden && (
                    <div className={clsx("text__center", styles.hideForm)}>
                      <Button variants="gray" rounded onClick={() => setIsFormHidden(false)}>
                        フォームを入力する
                        <i className={clsx("fas", "fa-angle-down")} />
                      </Button>
                    </div>
                  )}
                </div>
              </main>
            </div>
            {!amazonPayEnable && (
              <div className={styles.processFixed}>
                <CheckoutProgressBar
                  percentage={formPercentage}
                  unfilledCount={formUnfilledCount}
                  onClickConfirm={handleClickConfirm}
                />
              </div>
            )}
          </div>
        </LoadingOverlay>
      );
    },
    <Row className={styles.loaderContainer}>
      <FadeLoader color={theme.yellow} loading />
    </Row>
  ),
  <Row className={styles.loaderContainer}>
    <FadeLoader color={theme.yellow} loading />
  </Row>
);
