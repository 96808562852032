import { useCallback, useMemo } from "react";

import { deepEqual } from "fast-equals";
import { DeepPartial } from "react-hook-form";
import { z } from "zod";

import { CheckoutFormValues, convertFormValuesToStorage } from "@/components/domains/checkout-form";
import { createOrderPost } from "@/generated/axios-functions/payseAPI";
import { useValidateCart } from "@/generated/open-api/cart/cart";
import { Address, ValidateCartBody } from "@/generated/open-api/schemas";
import { CartModel } from "@/models/cart/type";
import { DeliveryReceiveOption } from "@/models/delivery/consts";
import { convertProductToNuxtStoreProduct } from "@/models/product/converters";
import { useClientCartForm, useClientFrontStoreCartItems } from "@/storage";
import { NuxtStoreCart } from "@/storage/types";
import { NuxtStoreCartForm } from "@/storage/types/NuxtStoreCartForm";
import { setFormDataForAmazonPay, setupAmazonPay } from "@/utils/amazon";
import { ValidationError } from "@/utils/error";
import { useQueryParams } from "@/utils/hooks/useQueryParams";
import { objectEntries, objectKeys, objectMerge, objectValues } from "@/utils/object";
import { isValidProvince, isValidZipCode } from "@/utils/zipCode";

export class CartValidator {
  static validateAll = (form: NuxtStoreCart["form"], cart: CartModel | null) => {
    const validators = {
      email: this.email,
      shipping_address: this.shippingAddress,
      different_billing_address: this.address,
      delivery_date: this.deliveryDate,
      delivery_timezone: this.deliveryTimezone,
      payment_method: this.paymentMethod,
      payment_data: this.paymentData,
    };

    // 各フィールドのバリデーターを呼び出し、エラーメッセージを収集
    const errors = objectEntries(validators).reduce((acc, [field, validator]) => {
      const newErrors = validator(form, cart, field);
      return [...acc, ...newErrors];
    }, [] as string[]);

    return errors;
  };

  static email = (form: NuxtStoreCart["form"]) => {
    const email = form.email;
    if (!email) return ["メールアドレスは必須です"];
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.([a-zA-Z0-9-.]{2,})*$/;
    return re.test(email) ? [] : ["メールアドレスの形式が不正です"];
  };

  static isValidZip = (zip: string | undefined | null) => {
    if (!zip) return false;
    return /^[0-9]{3}-[0-9]{4}$|^[0-9]{3}[0-9]{4}$/.test(zip);
  };

  static isValidPhone = (phone: string | undefined | null) => {
    if (!phone) return false;
    // AmazonPayからハイフンのある電話番号が入力されるケースに対応
    phone = phone.replace(/[^0-9]/g, "");
    return phone.match(/^[0-9]{10,11}$/);
  };

  static shippingAddress = (form: NuxtStoreCart["form"], cart: CartModel | null, field: string) => {
    const address = form[field as "shipping_address"];
    if (!address) return [`配送先住所は必須です`];
    return this.address(form, cart, field);
  };

  static address = (form: NuxtStoreCart["form"], _cart: CartModel | null, field: string) => {
    const address = form[field as "shipping_address" | "different_billing_address"];
    if (!address) return [];
    const label = field === "shipping_address" ? "配送先" : "請求先";
    const errors = [];
    if (!address.last_name) errors.push(`${label}の姓は必須です`);
    if (!address.zip) errors.push(`${label}の郵便番号は必須です`);
    if (!address.province) errors.push(`${label}の都道府県は必須です`);
    if (!address.city) errors.push(`${label}の市区町村は必須です`);
    if (!address.address_line1) errors.push(`${label}の住所は必須です`);
    if (!address.phone) errors.push(`${label}の電話番号は必須です`);
    return errors;
  };

  static deliveryDate = (form: NuxtStoreCart["form"]) => {
    return form.delivery_date ? [] : ["配送希望日は必須です"];
  };

  static deliveryTimezone = (form: NuxtStoreCart["form"]) => {
    return form.delivery_timezone ? [] : ["配送時間帯は必須です"];
  };

  static paymentMethod = (form: NuxtStoreCart["form"], cart: CartModel | null) => {
    const paymentMethod = form.payment_method;
    if (paymentMethod) return [];
    // 単発購入 かつ すべてポイント払いの場合は支払い方法は必須ではない
    return cart !== null &&
      cart.usePointType === "all" &&
      cart.totalPrice === 0 &&
      !cart.isSubscription
      ? []
      : ["支払い方法は必須です"];
  };

  static paymentData = (form: NuxtStoreCart["form"]) => {
    const method = form.payment_method;
    const paymentData = form.payment_data;
    const errors = [];
    if (method === "amazon" && !paymentData?.amazon_checkout_session_id) {
      errors.push("AmazonPayの認証情報が不正です。");
    } else if (method === "credit" && !paymentData?.stripe_token) {
      errors.push("カード情報の取得に失敗しました。");
    }
    return errors;
  };
}

export const CheckoutQueryParamsSchema = z.object({
  cart: z.object({
    products: z.array(
      z.object({
        variant_id: z.string(),
        subscription: z.boolean(),
        quantity: z.number(),
      })
    ),
    set_names: z.array(z.string()).optional(),
  }),
});

export type CheckoutQueryParams = z.infer<typeof CheckoutQueryParamsSchema>;

/**
 * カートを取得するのに必要なパラメーターの取得、状態管理を行う
 * @remarks
 * Client Side renderingでのみ対応
 */
export const useCartParamsState = () => {
  const urlParams = useQueryParams<CheckoutQueryParams>();
  const { cartItems } = useClientFrontStoreCartItems();
  const { form, setForm } = useClientCartForm();

  const formParams = useMemo(
    () => ({
      paymentMethod: form?.payment_method ?? "credit",
      coupon: form?.coupon ?? "",
      usePoint: form?.use_point ?? 0,
      usePointType: form?.use_point_type ?? "none",
    }),
    [form]
  );

  const cartParams = useMemo(() => {
    const result = CheckoutQueryParamsSchema.safeParse(urlParams);
    if (result.success) {
      // URLから取得したカート情報が有効であれば、その情報を使う
      const parsedParams = result.data;
      return {
        ...formParams,
        products:
          parsedParams.cart.products.map((product) => ({
            variantId: product?.variant_id ?? "",
            subscription: product?.subscription ?? false,
            quantity: String(product?.quantity ?? 0),
          })) ?? [],
      };
    } else if (form?.products) {
      // フォームに保存された商品情報を利用する
      return {
        ...formParams,
        products: form.products.map((item) => ({
          variantId: item.variant_id.toString(),
          quantity: item.quantity.toString(),
          subscription: item.subscription,
        })),
      };
    } else {
      // FrontStoreに保存されたカート情報を使う
      return {
        ...formParams,
        products: cartItems.map((item) => ({
          variantId: item.variantId.toString(),
          quantity: item.quantity.toString(),
          subscription: item.subscription,
        })),
      };
    }
  }, [cartItems, form?.products, formParams, urlParams]);

  const setCouponCode = useCallback(
    (coupon: string | null) => {
      setForm((prev) => ({ ...prev, coupon: coupon ?? "" }));
    },
    [setForm]
  );

  const setPoints = useCallback(
    (points: number) => {
      setForm((prev) => ({
        ...prev,
        use_point: points,
        use_point_type: points > 0 ? "all" : "none",
      }));
    },
    [setForm]
  );
  return {
    cartParams,
    setCouponCode,
    setPoints,
  };
};

export const createOrder = async (formValues: NuxtStoreCart["form"]) => {
  try {
    const res = await createOrderPost({
      ...formValues,
      accepts_marketing: Boolean(formValues.accepts_marketing),
      create_account: Boolean(formValues.create_account),
      delivery_date: formValues.delivery_date ?? "free",
      delivery_timezone: formValues.delivery_timezone ?? "free",
      email: formValues.email ?? "",
      payment_data: formValues.payment_data!,
      payment_method: formValues.payment_method ?? "",
      shipping_address: formValues.shipping_address as Address,
      products: formValues.products ?? [],
      different_billing_address: formValues.different_billing_address as Address | undefined,
      non_face_to_face_receipt:
        formValues.delivery_location_code === DeliveryReceiveOption.FaceToFace,
      set_names: "[]",
    });
    return res;
  } catch (e) {
    throw e;
  }
};

interface UseFormPercentagesParams {
  formValues: DeepPartial<CheckoutFormValues>;
  isLoggedIn: boolean;
  isPaymentMethodUnnecessary: boolean;
}

const fieldScores = {
  shippingAddress: {
    lastName: 10,
    firstName: 10,
    zip: 10,
    city: 10,
    addressLine1: 10,
    phone: 10,
  },
  email: 10,
  password: 15,
  paymentMethod: 15,
};

export const useFormPercentage = ({
  formValues,
  isLoggedIn,
  isPaymentMethodUnnecessary,
}: UseFormPercentagesParams) => {
  return useMemo(() => {
    let percentage = 0;
    // 分母 = 住所入力欄 + メールアドレス + パスワード + 支払方法
    let unfilledCount =
      objectKeys(fieldScores.shippingAddress).length + objectKeys(fieldScores).length - 1;

    const { shippingAddress = {}, paymentData, credentials } = formValues ?? {};

    objectKeys(fieldScores.shippingAddress).map((key) => {
      if (shippingAddress[key]) {
        percentage += fieldScores.shippingAddress[key];
        unfilledCount--;
      }
    });

    if ((credentials && credentials.email) || isLoggedIn) {
      percentage += fieldScores.email;
      unfilledCount--;
    }

    if ((credentials && credentials.password) || isLoggedIn) {
      percentage += fieldScores.password;
      unfilledCount--;
    }

    if (paymentData?.paymentMethod || isPaymentMethodUnnecessary) {
      percentage += fieldScores.paymentMethod;
      unfilledCount--;
    }

    return { percentage, unfilledCount };
  }, [formValues, isLoggedIn, isPaymentMethodUnnecessary]);
};

export function convertNuxtStoreCartFormToValidateCartBody(
  form: NuxtStoreCartForm
): ValidateCartBody {
  return {
    ...form,
    different_billing_address: form.different_billing_address as Address,
    email: form.email || "",
    isInvite: form.isInvite || false,
    isLP: form.isLP || false,
    products: form.products ?? [],
    shipping_address: form.shipping_address as Address,
    non_face_to_face_receipt: form.delivery_location_code === DeliveryReceiveOption.FaceToFace,
  };
}

export const convertToCartFormValues = (params: UseConfirmCallbackParams) => {
  const {
    cart,
    formValues,
    isLoggedIn,
    isPaymentMethodUnnecessary,
    hasSameEmailAccount,
    amazonPayEnable,
    amazonPayCheckoutSessionId,
  } = params;

  let formParams: NuxtStoreCartForm = {
    ...convertFormValuesToStorage(formValues),
    products: cart?.products?.map(convertProductToNuxtStoreProduct) ?? [],
    isLP: false,
    isInvite: false,
    hasAccount: isLoggedIn,
    use_point: cart.usePoint,
    use_point_type: cart.usePointType,
    create_account: !isLoggedIn && !hasSameEmailAccount,
  };
  const { paymentData } = formValues as CheckoutFormValues;

  if (isPaymentMethodUnnecessary) {
    // ポイントのみで支払いが完了する場合
    formParams = { ...formParams, payment_method: "pointOnly" };
  } else if (amazonPayEnable) {
    // AmazonPayが有効化されている場合
    formParams = {
      ...formParams,
      payment_method: "amazon",
      payment_data: {
        ...formParams.payment_data,
        amazon_checkout_session_id: amazonPayCheckoutSessionId ?? undefined,
      },
    };
  } else if (paymentData.paymentMethod === "credit") {
    if (!paymentData.stripeToken || !paymentData.last4) {
      throw new ValidationError("クレジットカード情報を入力してください");
    }
    formParams = {
      ...formParams,
      payment_method: "credit",
      payment_data: {
        ...formParams.payment_data,
        stripe_token: paymentData.stripeToken,
        last4: paymentData.last4,
      },
    };
  }

  // 請求書左記住所が配送先と一緒であれば契約先住所は不要のためリクエスト情報から削除する
  if (
    !formValues.billingAddressSelect ||
    deepEqual(formValues.shippingAddress, formValues.billingAddress)
  ) {
    formParams = { ...formParams, different_billing_address: undefined };
  }
  return formParams;
};

interface UseConfirmCallbackParams {
  cart: CartModel;
  formValues: DeepPartial<CheckoutFormValues>;
  isLoggedIn: boolean;
  isPaymentMethodUnnecessary: boolean;
  hasSameEmailAccount: boolean;
  amazonPayEnable: boolean;
  amazonPayCheckoutSessionId: string | null;
}

export const useConfirm = () => {
  const { mutateAsync: validateCart } = useValidateCart();
  /**
   * formの値をlocalStorageとの同期
   * 別画面遷移時に状態の同期をとるための処理
   */
  const { setForm } = useClientCartForm();

  return useCallback(
    async (params: UseConfirmCallbackParams) => {
      const { cart, formValues, amazonPayEnable } = params;
      const { paymentData, shippingAddress } = formValues;
      const cartFormValues = convertToCartFormValues(params);
      const validateCartParams = convertNuxtStoreCartFormToValidateCartBody(cartFormValues);

      if (paymentData?.paymentMethod === "amazon" && !amazonPayEnable) {
        // 支払い方法でAmazonPayを選択しているが、AmazonPayが有効化されていない場合
        setFormDataForAmazonPay("amazon_pay_input_data", cartFormValues);
        setupAmazonPay({
          reviewReturnPath: "/checkout",
          resultReturnPath: "/checkout/confirm",
          isSubscription: cart?.isSubscription ?? false,
        });
        return;
      }

      const { province, zip } = shippingAddress ?? {};

      if (!(province && isValidProvince(province))) {
        throw new ValidationError("都道府県が正しくありません");
      }
      if (!(zip && (await isValidZipCode(zip)))) {
        throw new ValidationError("郵便番号が正しくありません");
      }

      setForm((prev) => objectMerge(prev, cartFormValues));
      const res = await validateCart({ data: validateCartParams });
      const cartErrors = objectValues(res.exception?.errors ?? {}).flatMap((errors) => errors);
      if (cartErrors.length) {
        throw new ValidationError(cartErrors.join("\n"));
      }
    },
    [setForm, validateCart]
  );
};
